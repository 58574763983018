<template>
  <CContainer>
    <CCard class="p-4">
      <div class="max-w-xs mx-auto">
        <CSegmentControl v-model="segment">
          <CSegmentControlItem :label="$t('map')" value="map" icon="map" />
          <CSegmentControlItem :label="$t('list')" value="list" icon="view-list" />
        </CSegmentControl>
      </div>
      <div v-if="mapMode" class="my-2 flex justify-end">
        <CLink class="text-gray-600" @click="$refs.map.mapHelp = true">
          {{ $t('help') }}
          <CIcon name="question-mark-circle" size="16" class="ml-1" />
        </CLink>
      </div>
      <template>
        <MapSelector
          v-show="mapMode"
          class="border rounded-md h-[600px]"
          ref="map"
          :submit-loading="submitLoading"
          @delete="onDelete"
          @update="openUpdateModal"
          @submit-selected="createMultiple"
        />
        <div v-show="!mapMode" class="p-4 md:p-6 lg:p-8">
          <ListSelector
            @update="openUpdateModal"
            @open-create="createUpdateModal = true"
          />
        </div>
        <Portal to="overlays">
          <CreateUpdateModal
            v-model="createUpdateModal"
            :update-id="updateId"
            :update-data="updateData"
            @toggle="onUpdateModalToggle"
          />
        </Portal>
      </template>
    </CCard>
  </CContainer>
</template>

<script>
import { mapActions } from 'vuex';
import MapSelector from '@/ui/components/Merchant/DeliveryAreas/MapSelector.vue';
import ListSelector from '@/ui/components/Merchant/DeliveryAreas/ListSelector.vue';
import CreateUpdateModal from '@/ui/components/Merchant/DeliveryAreas/CreateUpdateModal.vue';
import {
  CREATE_DELIVERY_AREA,
  DELETE_DELIVERY_AREA,
  GET_DELIVERY_AREAS,
} from '@/store/actionTypes';
import germanDecimalParser from '@/utils/germanDecimalParser';

export default {
  components: { MapSelector, ListSelector, CreateUpdateModal },

  data: () => ({
    segment: 'map',
    submitLoading: false,

    createUpdateModal: false,
    updateId: null,
    updateData: {
      postalCode: null,
      additionalPrice: null,
    },
  }),

  computed: {
    mapMode() {
      return this.segment === 'map';
    },
  },

  created() {
    this.getDeliveryAreas();
  },

  methods: {
    ...mapActions([CREATE_DELIVERY_AREA, DELETE_DELIVERY_AREA, GET_DELIVERY_AREAS]),
    onDelete(id) {
      this.deleteDeliveryArea(id);
    },
    async createMultiple({ country, postalCodes, resetSelection }) {
      this.submitLoading = true;
      try {
        await Promise.all(
          postalCodes.map((postalCode) => {
            return this.createDeliveryArea({
              postalCodeId: `${country}-${postalCode}`,
              additionalPrice: 0,
            });
          }),
        );
      } finally {
        resetSelection(true);
        this.submitLoading = false;
      }
    },
    openUpdateModal({ id, data }) {
      this.updateId = id;
      this.updateData.postalCode = data.postalCodeId;
      this.updateData.additionalPrice = germanDecimalParser(data.additionalPrice || 0, true);
      this.updateData.ignoreDeliveryDistancePrice = data.ignoreDeliveryDistancePrice;
      this.updateData.location = data.location;
      this.createUpdateModal = true;
    },
    onUpdateModalToggle(show) {
      if (!show) {
        setTimeout(() => {
          this.updateId = null;
          this.updateData = {
            postalCode: null,
            additionalPrice: null,
          };
        }, 300);
      }
    },
    onContinue() {
      if (this.mapMode) {
        this.segment = 'list';
      } else {
        this.$emit('close');
        setTimeout(() => {
          this.segment = 'map';
        }, 300);
      }
    },
  },
};
</script>
